import "@fontsource/dancing-script/latin-400.css"

<let/quote />
<const/rollQuote() {
	const random = <Type,>(arr: readonly Type[]): Type =>
		arr[Math.floor(Math.random() * arr.length)]

	quote = random(
		[
			"Have anyone hating on Electron try to make an HTTP server responding with a stream in actix-web and they'll be worshipping Brendan Eich in no time",
			"When I write a GTK app, I shouldn't be manually updating properties like a Roblox script",
			"I don't wanna use anything made by people who know what Homestuck is",
			"Telemetry can be good, that's why I have all of it enabled on Firefox",
			"Linux users complain about software that runs slow, but not software that makes YOU slow",
			"I need to add Marko, I need to add Marko so bad",
			"Every day I feel more and more like a techbro",
			"Who is Mike and why is he in my feedback form",
			"Josh is like the DJ Khaled of Arc",
			"trollsmile is now scanning you. These DMs.... property of trollsmile....",
			"Etcetera has therefore decided that your startup should be destroyed",
			"Step 5: Toot. Step 6: Motion",
			"@Sales Convince this user",
			"This is why you should never trust people who do their shit lowercase",
		].filter((q) => q !== quote),
	)
} />
<lifecycle onMount=rollQuote />
<legend role="none">
	<span role="heading" aria-level="2">Philosophy</span>
	${" "}
	<button onClick=rollQuote>🎲 Another quote</button>
</legend>
<figure>
	<blockquote>
		<noscript>JavaScript is required to view my insights</noscript>${quote}
	</blockquote>
	<figcaption>―Jack Wavesmiley</figcaption>
</figure>

<style>
	@media (prefers-color-scheme: light) {
		#philosophy legend span {
			background: Canvas;
			color: CanvasText;
		}
	}
	#philosophy {
		background: #000 url(./beach.webp) center/cover;
		color: #fff;
	}
	#philosophy figure {
		font-family: "Dancing Script", cursive;
		margin: 0;
		padding: 2em;
	}
	#philosophy blockquote {
		text-align: center;
		filter: drop-shadow(0 0 5px);
		margin: 0;
		display: block;
		font-size: xx-large;
	}
	#philosophy figcaption {
		text-align: right;
	}
	@media (scripting: none) or (scripting: initial-only) {
		#philosophy button {
			display: none;
		}
	}
</style>
